<template>

  <div>

    <CheckCardStatus v-if="order.transaction.method.type == 'CARD'" :order="order" v-on="$listeners"></CheckCardStatus>

    <ConfirmTransfer v-if="order.transaction.method.type == 'TRANSFER'" :order="order" v-on="$listeners"></ConfirmTransfer>

  </div>

</template>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'

import CheckCardStatus from './payment/CheckCardStatus.vue';
import ConfirmTransfer from './payment/ConfirmTransfer.vue';


export default defineComponent({
  props: {
      order : {
        type: Object,
        required: true
      }
  },
  components: {
    CheckCardStatus,
    ConfirmTransfer
  },
  setup(props, context) {

    const { order } = toRefs(props);

    return {
      order
    }
  }
})
</script>