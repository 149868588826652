import Vue from '@fwk-node-modules/vue';

/**
 * getPaymentMethodLabel
 */
export function getPaymentMethodLabel (vm:Vue, order:any) {
    var methodOfPayment = order.transaction.method.type
    return vm.$t('shop.orders.methodsOfPayment.'+methodOfPayment) as string;
}

/**
 * getPaymentStatusLabel
 */
export function getPaymentStatusLabel (vm:Vue, order:any) {
    var transactionStatus = order.transaction.status;
    return vm.$t('shop.orders.paymentStatus.'+transactionStatus) as string;
}

/**
 * getOrderStatusLabel
 */
export function getOrderStatusLabel (vm:Vue, order:any) {
    var orderStatus = order.status;
    return vm.$t('shop.orders.orderStatus.'+orderStatus) as string;
}

/**
 * getOrderDeliveryTypeLabel
 */
export function getOrderDeliveryTypeLabel (vm:Vue, order:any) {
    var deliveryType = vm.$shop.delivery.getOrderDeliveryType(order);
    return vm.$t('shop.orders.methodsOfDelivery.'+deliveryType) as string;
}