<template>
  
    <form role="form" name="review-order" class="review-order">

      <CartRecap :order="order"></CartRecap>

      <hr/>

      <DeliveryRecap :order="order"></DeliveryRecap>

      <hr/>

      <ContactRecap :order="order"></ContactRecap>

      <hr/>

      <PaymentRecap :order="order"></PaymentRecap>

      <div v-if="order.status == 'REVIEW'">

        <hr/>

        <button class="btn btn-secondary" @click="showCancelForm" type="button">{{$t('shop.order.review.cancel.button')}}</button>
        <button class="btn btn-primary" @click="showConfirmForm" type="button">{{$t('shop.order.review.confirm.button')}}</button>

        <div :style="{ display : isCancel ? 'block' : 'none' }">
          <hr/>
          <h3>{{ $t('shop.order.review.cancel.title') }}</h3>
          <p>{{ $t('shop.order.review.cancel.note') }}</p>
          <div class="form-group">
              <label for="cancelReason" class="sr-only">{{$t('shop.order.info.cancel.reason')}}</label>
              <textarea class="form-control" v-model="form.cancel.reason"></textarea>
          </div>
          
          <button class="btn btn-primary ladda-button cancel" data-style="zoom-in" type="button" @click="onCancelFormSubmit">{{$t('shop.order.review.cancel.button')}}</button>
        </div>

        <div :style="{ display : isConfirm ? 'block' : 'none' }">
          <hr/>
          <h3>{{ $t('shop.order.review.confirm.title') }}</h3>
          <p>{{ $t('shop.order.review.confirm.note') }}</p>
          <button class="btn btn-primary ladda-button confirm" data-style="zoom-in" type="button" @click="onConfirmFormSubmit">{{$t('shop.order.review.confirm.button')}}</button>
        </div>
      
      </div>
      
    </form>

</template>

<style>

  .review-order hr {
    border-top: 2px solid rgba(0,0,0,.3);
    margin-left: -20px;
    margin-right: -20px;
  }

  .review-order td.header {
    white-space: nowrap;
  }

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import CartRecap from '../recap/CartRecap.vue';
import DeliveryRecap from '../recap/DeliveryRecap.vue';
import ContactRecap from '../recap/ContactRecap.vue';
import PaymentRecap from '../recap/PaymentRecap.vue';

import * as Ladda from 'ladda';

import { useOrderAdmin } from '../../../../composables/useOrderAdmin';

export default defineComponent({
  props: {
      order : {
        type: Object,
        required: true
      }
  },
  emits: ["updated"],
  components: {
    CartRecap,
    DeliveryRecap,
    ContactRecap,
    PaymentRecap  
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { reviewConfirm, reviewCancel } = useOrderAdmin(props, context);

    const isCancel:Ref<boolean> = ref(false);
    const isConfirm:Ref<boolean> = ref(false);

    const { order } = toRefs(props);

  
    const form = reactive({
      cancel : {
        reason : ''
      }
    })

    var laddaCancelSubmit:Ladda.LaddaButton|null = null;
    var laddaConfirmSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var cancelButton:HTMLButtonElement|null = document.querySelector( 'form[name=review-order] button.cancel' );
      laddaCancelSubmit = Ladda.create(cancelButton!);

      var confirmButton:HTMLButtonElement|null = document.querySelector( 'form[name=review-order] button.confirm' );
      laddaConfirmSubmit = Ladda.create(confirmButton!);

      form.cancel.reason = app.$t('shop.order.info.cancel.reason_default') as string;
    });

    const onCancelFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaCancelSubmit!.start();

      reviewCancel(order.value._id, form.cancel.reason).then((cancelled:boolean) => {
        laddaCancelSubmit!.stop();
      })
    }

    const onConfirmFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaConfirmSubmit!.start();

      reviewConfirm(order.value._id).then((result:any) => {
        if(result.confirmed) {
          context.emit("updated", result.order);
        }
        laddaConfirmSubmit!.stop();
      })
    }

    const showCancelForm = () => {
      isCancel.value = true;
      isConfirm.value = false;
    }

    const showConfirmForm = () => {
      isConfirm.value = true;
      isCancel.value = false;
    }

    return {
      order,
      form,
      onCancelFormSubmit,
      onConfirmFormSubmit,
      showCancelForm,
      showConfirmForm,
      isCancel,
      isConfirm
    }
  }
})
</script>