<template>

  <form role="form" @submit="onFormSubmit" name="payment-check-status">
    <h3>{{ $t('shop.order.checkPayment.title', {orderID: order._id}) }}</h3>
    <p>{{ $t('shop.order.checkPayment.note') }}</p>
    <div class="form-group">
        <span>{{$t('shop.orders.headers.methodOfPayment')}}: </span>{{ getPaymentMethodLabel() }}
    </div>
    <div class="form-group">
        <span>{{$t('shop.orders.headers.paymentStatus')}}: </span>{{ getPaymentStatusLabel() }}
    </div>
    <div class="form-group">
        <span>{{$t('shop.orders.headers.status')}}: </span>{{ getOrderStatusLabel() }}
    </div>
    <button v-if="order.status == 'PAYMENT'" class="btn btn-primary ladda-button check-status" data-style="zoom-in" type="submit">{{$t('shop.order.checkPayment.button')}}</button>
    <button v-if="order.status == 'PAYMENT' && hasUserRole(apiRoles.superadmin)" class="btn btn-primary ladda-button convert-transfer" data-style="zoom-in" type="button" @click="convertTransfer">{{$t('shop.order.checkPayment.convert-transfer')}}</button>
  </form>

</template>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { authenticationTypes } from '@fwk-client/store/types';
import * as shop from '@root/src/client/utils/shop';
import * as Ladda from 'ladda';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';

import { useOrderAdmin } from '../../../../../composables/useOrderAdmin';

export default defineComponent({
  props: {
      order : {
        type: Object,
        required: true
      }
  },
  components: {
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { checkCardStatus, convertPaymentToTransfer } = useOrderAdmin(props, context);

    const { order } = toRefs(props);

    const hasUserRole = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

    var laddaSubmit:Ladda.LaddaButton|null = null;
    var laddaConvertTransfer:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=payment-check-status] button.check-status' );
      laddaSubmit = Ladda.create(submitButton!);

      var convertTransferButton:HTMLButtonElement|null = document.querySelector( 'form[name=payment-check-status] button.convert-transfer' );
      laddaConvertTransfer = Ladda.create(convertTransferButton!);
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      checkCardStatus(order.value._id).then((result:any) => {
        if(result.updated) {
          context.emit("updated", result.order);
        }
        laddaSubmit!.stop();
      })
    }

    const convertTransfer = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaConvertTransfer!.start();
      
      convertPaymentToTransfer(order.value._id).then((result:any) => {
        laddaConvertTransfer!.stop();
      });
    }

    const getPaymentMethodLabel = () => {
      return shop.getPaymentMethodLabel(app, order.value);
    }

    const getPaymentStatusLabel = () => {
      return shop.getPaymentStatusLabel(app, order.value);
    }
    
    const getOrderStatusLabel = () => {
      return shop.getOrderStatusLabel(app, order.value);
    }

    return {
      onFormSubmit,
      order,
      getPaymentMethodLabel,
      getPaymentStatusLabel,
      getOrderStatusLabel,
      hasUserRole,
      apiRoles,
      convertTransfer
    }
  }
})
</script>