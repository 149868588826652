<template>
    <div>
        <h3>{{ $t('shop.order.info.contact.info.title') }}</h3>

        <div class="table-responsive">
            <table class="table">
                <tbody>
                    <tr>
                        <td class="header">Email</td>
                        <td class="col">
                            {{ order.contact.email.email }}
                        </td>
                    </tr>
                    <tr v-if="order.contact.companyName">
                        <td class="header">Entreprise</td>
                        <td class="col">
                            {{ order.contact.companyName }}
                        </td>
                    </tr>
                    <tr>
                        <td class="header">Nom</td>
                        <td class="col">
                            {{ order.contact.firstname + " " + order.contact.lastname}}
                        </td>
                    </tr>
                    <tr>
                        <td class="header">Telephone</td>
                        <td class="col">
                            {{ order.contact.phone.number}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h3>{{ $t('shop.order.info.contact.address.title') }}</h3>

        <Address :address="order.contact.address"></Address>

    </div>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import Address from './Address.vue';

@Component({
    components: {
        Address
    },
    computed: {},
})
export default class ContactRecap extends Vue {
    
    @Prop({
        type: Object,
        required: true
    }) readonly order!: any

}

</script>