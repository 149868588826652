<template>
    <div>
        <h3>{{ $t('shop.order.info.cart') }}</h3>
        <Products v-if="products.length > 0" :products="products"></Products>
        <Activities v-if="activities.length > 0" :products="activities"></Activities>
    </div>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import Products from './Products.vue';
import Activities from './Activities.vue';

@Component({
    components: {
        Products,
        Activities
    },
    computed: {},
})
export default class CartRecap extends Vue {

    @Prop({
        type: Object,
        required: true
    }) readonly order!: any

    get products() {
        return this.order.products.filter((product:any) => product.type == 'PRODUCT');
    }

    get activities() {
        return this.order.products.filter((product:any) => product.type == 'ACTIVITY');
    }

}

</script>