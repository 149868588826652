<template>
    <div class="row m-b-lg m-t-lg">
        <div class="col-md-6">
          <div v-if="logoURL" class="profile-image">
              <img :src="logoURL" class="rounded-circle circle-border" alt="logo">
          </div>
          <div :class="(logoURL ? 'profile-info' : '')">
            <h2 class="no-margins">{{ name }}</h2>
            <p>
              {{$t('cms.info.company')}} {{ companyName }}<br/>
            </p>
          </div>
        </div>
        <div class="col-md-6">
            <ShopSwitch v-if="shops.length > 1" class="mb-3"></ShopSwitch>
        </div>
        <div v-if="shopContentValidationLink || siteValidationLink" class="col-md-12">
          <a v-if="shopContentValidationLink" class="btn btn-primary" :href="shopContentValidationLink" target="_blank">{{$t('shop.deployment.validation.button-shop-content')}}</a>
          <a v-if="siteValidationLink" class="btn btn-primary" :href="siteValidationLink" target="_blank">{{$t('shop.deployment.validation.button-site', {'domain': siteTopLevelDomain})}}</a>
        </div>
      </div>
</template>

<script lang="ts"> 
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import ShopSwitch from './ShopSwitch.vue';

import { useShopAdmin } from '../../composables/useShopAdmin';
import { useSiteAdmin } from '../../../cms/composables/useSiteAdmin';
import { useVigneronOnlineAdmin } from '../../../vigneron-online/composables/useVigneronOnlineAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      ShopSwitch
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedShop, shops, selectedShopContent, selectedSite } = useShopAdmin(props, context);
      const { getShopContentValidationLink } = useVigneronOnlineAdmin(props, context);
      const { getSiteValidationLink } = useSiteAdmin(props, context);

      const logoURL = null;

      const name = computed(() => {
        if(selectedShop && selectedShop.value.shop) { return selectedShop.value.shop.name; }
        return "";
      })

      const companyName = computed(() => {
        if(selectedShop && selectedShop.value.shop && selectedShop.value.shop.company) { return selectedShop.value.shop.company.name; }
        return "-";
      })

      const id = computed(() => {
        if(selectedShop) { return selectedShop.value.shop._id; }
        return "-";
      })

      const shopContentValidationLink = computed(() => {
        return getShopContentValidationLink(selectedShopContent.value);
      }) 

      const siteTopLevelDomain = computed(() => {
        return selectedSite.value ? selectedSite.value.topLevelDomain : undefined;
      })

      const siteValidationLink = computed(() => {
        return getSiteValidationLink(selectedSite.value);
      });

      return { 
        shops,
        selectedShop,
        logoURL,
        name,
        companyName,
        id,
        shopContentValidationLink,
        siteTopLevelDomain,
        siteValidationLink
      }
    }
})
</script>