<template>
    
    <div ref="payment">
      <h3>{{ $t('shop.order.info.methodOfPayment.title') }}: <span>{{ getPaymentTitle() }}</span></h3>

      <div class="table-responsive" v-if="this.transaction.method.type == 'CARD'">
        <table class="table">
            <tbody>
                <tr>
                    <td class="header">{{$t('shop.orders.headers.paymentStatus')}}</td>
                    <td class="col">
                      {{ getPaymentStatusLabel() }}
                    </td>
                </tr>
                <tr v-if="this.transaction.details">
                    <td class="header">Details</td>
                    <td class="col">
                        {{ this.transaction.details.type }} {{ this.transaction.details.number }}
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
      <PromoCodes :order="order" />
      <div class="row">
        <div class="col"></div>
        <div class="col-lg-6 clearfix">
          <div class="table-responsive">
            <table class="table cart amounts">
              <tbody>
                <tr class="cart_item">
                  <td class="cart-product-name">
                    <strong>{{ $t("shop.order.info.cart-total") }}</strong>
                  </td>

                  <td class="cart-product-name">
                    <span class="amount">{{ formatPriceAmount(getOrderProductsAmount()) }}</span>
                  </td>
                </tr>
                <tr class="cart_item">
                    <td class="cart-product-name">
                        <strong>{{ $t("shop.order.info.delivery-total") }}</strong>
                    </td>
                    <td class="cart-product-name">
                        <span class="amount">{{ formatPriceAmount(getOrderDeliveryAmount()) }}</span>
                    </td>
                </tr>
                <tr class="cart_item" v-if="getOrderPromoCodesReduction() != null">
                    <td class="cart-product-name">
                        <strong>{{ $t("shop.order.info.promoCodes-total") }}</strong>
                    </td>
                    <td class="cart-product-name">
                        <span class="amount">- {{ formatPriceAmount(getOrderPromoCodesReduction()) }} </span>
                    </td>
                </tr>
                <tr class="cart_item">
                  <td class="cart-product-name">
                    <span class="amount color lead"><strong>{{ $t("shop.order.info.order-total") }}</strong></span>
                  </td>

                  <td class="cart-product-name">
                    <span class="amount color lead"><strong>{{ formatPriceAmount(getOrderTotalAmount()) }}</strong></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
       
</template>

<style>

  .button.payment {
    width: 100%;
  }

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { utils } from '@fwk-client/modules/shop/stores/order';
import { containerUtils } from '@fwk-client/modules/shop/stores/index';
import PromoCodes from './PromoCodes.vue';
import * as shop from '@root/src/client/utils/shop';

@Component({
    components: {
      PromoCodes
    },
    computed: {
    },
})
export default class PaymentRecap extends Vue {

   @Prop({
        type: Object,
        required: true
    }) readonly order!: any

  get transaction() {
    if(this.order) {
      return this.order.transaction;
    }
  }


  getPaymentTitle() {
    return shop.getPaymentMethodLabel(this, this.order);
  }

  getPaymentStatusLabel() {
    return shop.getPaymentStatusLabel(this, this.order);
  }
  
  getOrderProductsAmount() {
    var productsAmount = containerUtils.getProductsAmount(this.order);
    if(productsAmount && productsAmount != 0) {
      return  productsAmount;
    }
    return null;
  }

  getOrderPromoCodesReduction() {
    var reduction =  containerUtils.getPromoCodesReduction(this.order);
    return reduction;
  }

  getOrderDeliveryAmount() {
    var deliveryAmount = utils.getOrderDeliveryAmount(this.order);
    if(deliveryAmount && deliveryAmount != 0) {
      return  deliveryAmount;
    }
    return null;
  }

  getOrderTotalAmount() {
    var totalAmount = utils.getOrderTotalAmount(this.order);
    if(totalAmount && totalAmount != 0) {
      return  totalAmount;
    }
    return null;
  }
}

</script>