<template>
    <table class="table cart">
        <thead>
            <tr>
                <th class="cart-product-name">{{ $t("shop.products.activities.headers.reference") }}</th>
                <th class="cart-product-name">{{ $t("shop.products.activities.headers.title") }}</th>
                <th class="cart-product-price">{{ $t("shop.products.activities.headers.price") }}</th>
                <th class="cart-product-quantity">{{ $t("shop.products.activities.headers.numberOfPersons") }}</th>
                <th class="cart-product-subtotal">{{ $t("shop.products.activities.headers.total-price") }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(cartProduct, index) in products" :key="index + '-cart-product'" class="cart_item" ref="cartProduct">

                <td class="cart-product-reference">
                    {{cartProduct.activity.reference}}
                </td>

                <td class="cart-product-name">
                    <div class="title">{{cartProduct.activity.title}}</div>
                    <div class="subtitle" v-if="cartProduct.activity.subtitle && cartProduct.activity.subtitle != ''">{{cartProduct.activity.subtitle}}</div>
                    <div>{{ formatDate(cartProduct.startDate) }}</div>
                    <div>{{ formatDateTime(cartProduct.startDate) }} - {{ formatDateTime(addMinutesToDate(new Date(cartProduct.startDate), cartProduct.activity.duration)) }}</div>
                </td>

                <td class="cart-product-price">
                    <span class="amount">{{ formatPriceAmount(cartProduct.activity.pricePerPerson) }}</span>
                </td>

                <td class="cart-product-quantity">
                    <span>{{ cartProduct.numberOfPersons }}</span>
                </td>

                <td class="cart-product-subtotal">
                    <span class="amount">{{ formatPriceAmount(cartProduct.amount) }}</span>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import { formatDay, FormatDateType, formatTime, FromLocalToUTC, formatDateForInput, FromUTCToLocal } from '@igotweb-node-api-utils/formatter';
import { addMinutes } from '@igotweb-node-api-utils/date';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
    components: {
    },
    computed: {},
})
export default class Activities extends Vue {

    @Prop({
        type: Array,
        required: true
    }) readonly products!: any[]

    get currentLanguageCode() {
        return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
    }

    formatDate = (date:Date) => {
        return formatDay(date, this.currentLanguageCode, {type: FormatDateType.LONG, isUTC:true});
    }

    formatDateTime = (date:Date) => {
        return formatTime(date, this.currentLanguageCode, {isUTC:true});
    }

    addMinutesToDate = (date:Date, minutes:number) => {
        return addMinutes(date, minutes);
    }

}

</script>