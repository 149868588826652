<template>
  <div class="ibox shops">
    <div class="ibox-title">
      <h2>{{ $t('shop.orders.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row m-b-md">
        <div class="col p-xs" v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</div>
        <div class="col-auto buttons">
          <div class="dateRange">
            <DateRangePicker v-if="false"
              ref="dateRangePicker"
              :max-date="new Date()"
              :locale-data="dateRangePickerLocalData"
              opens="left"
              :auto-apply="true"
              v-model="filters.dateRange"
            >
            <template #input="picker">
              <div style="min-width: 350px;">
                {{ getDateRangeLabel(picker.startDate, picker.endDate) }}
              </div>
            </template>
            </DateRangePicker>
          </div>
          <div class="includedStatus">
            <DropdownList
              label="Status"
              :list="availableStatus"
              :getLabel="getStatusLabel"
              v-bind:value.sync="filters.status" 
            />
          </div>
          <div class="refreshButton">
            <a class="btn btn-white" @click="onRefreshClick">
              <i class="fa fa-refresh"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="row">

        <b-pagination
            v-model="currentPage"
            :total-rows="orders.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>

      </div>

      <div class="row">
    
        <b-table 
            outlined striped
            :items="orders"
            :fields="listFields"
            :per-page="perPage"
            :current-page="currentPage"
            responsive
            ref="listItems">
          <template v-slot:cell(select)="row">
            <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
          </template>      
          <template v-slot:cell(_id)="row">
            {{ row.item._id }}
            <div>
              <a href="javascript:void(0)" @click="showOrderRecapModal(row.item)">{{$t('shop.orders.options.recap')}}</a> 
            </div>
          </template>  
          <template v-slot:cell(description)="row">
            <div v-if="orderProducts(row.item).length > 0">
              {{ $t('shop.products.products-title') }}: {{ orderProducts(row.item).length }}
            </div>
            <div v-if="orderActivities(row.item).length > 0">
              {{ $t('shop.products.activities-title') }}: {{ orderActivities(row.item).length }}
            </div>
            <div v-if="row.item.products && row.item.methodsOfDelivery.length > 0">
              {{ $t('shop.info.product.methodsOfDelivery') }}: {{ getMethodOfDeliveryLabel(row.item) }}
            </div>
          </template>  
          <template v-slot:cell(options)="row">
            <span v-if="row.item.options && row.item.options.isUpdateAllowed && row.item.status == 'PAYMENT'">
              <a href="javascript:void(0)" @click="showCheckPaymentModal(row.item)">{{$t('shop.orders.options.checkPayment')}}</a>
            </span>
            <span v-if="row.item.options && row.item.options.isSendReviewEmailAllowed && row.item.status == 'REVIEW'">
              <a href="javascript:void(0)" @click="showSendReviewEmailModal(row.item)">{{$t('shop.orders.options.sendReviewEmail')}}</a> / 
            </span>
            <span v-if="row.item.options && row.item.options.isUpdateAllowed && row.item.status == 'REVIEW'">
              <a href="javascript:void(0)" @click="showReviewModal(row.item)">{{$t('shop.orders.options.review')}}</a>
            </span>
            <span v-if="row.item.options && row.item.options.isUpdateAllowed && row.item.status == 'PREPARATION'">
              <a href="javascript:void(0)" @click="showPreparationModal(row.item)">{{$t('shop.orders.options.preparation')}}</a>
            </span>
            <span v-if="row.item.options && row.item.options.isUpdateAllowed && row.item.status == 'READY' && $shop.delivery.getOrderDeliveryType(row.item) == 'PICKUP'">
              <a href="javascript:void(0)" @click="showPickupModal(row.item)">{{$t('shop.orders.options.pickup')}}</a>
            </span>
            <span v-if="row.item.options && row.item.options.isUpdateAllowed && row.item.status == 'SHIPPED' && $shop.delivery.getOrderDeliveryType(row.item) == 'SHIPPING'">
              <a href="javascript:void(0)" @click="showCheckDeliveryModal(row.item)">{{$t('shop.orders.options.checkDelivery')}}</a>
            </span>
            <span v-if="row.item.options && row.item.options.isDeliveredAllowed">
              /
              <a href="javascript:void(0)" class="isDelivered" @click="confirmDeliveredItem(row.item)">{{$t('shop.orders.options.delivered')}}</a>
            </span>
            <span v-if="row.item.options && row.item.options.isRemoveAllowed">
              /
              <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('shop.orders.options.delete')}}</a>
            </span>
          </template>
        </b-table>

        <b-modal size="lg" ref="orderRecapModal" :title="$t('shop.order.review.title', {orderID: itemToUpdate._id})" hide-footer lazy>
          <OrderRecap :order="itemToUpdate" />
        </b-modal>
        
        <b-modal size="lg" ref="checkPaymentModal" :title="$t('shop.order.checkPayment.title', {orderID: itemToUpdate._id})" hide-footer lazy>
          <CheckPayment :order="itemToUpdate" v-on:updated="onItemUpdated" />
        </b-modal>

        <b-modal size="lg" ref="sendReviewEmailModal" :title="$t('shop.order.sendReviewEmail.title', {orderID: itemToUpdate._id})" hide-footer lazy>
          <SendReviewEmail :order="itemToUpdate" />
        </b-modal>

        <b-modal size="lg" ref="reviewModal" :title="$t('shop.order.review.title', {orderID: itemToUpdate._id})" hide-footer lazy>
          <ReviewOrder :order="itemToUpdate" v-on:updated="onItemUpdated" />
        </b-modal>

        <b-modal size="lg" ref="preparationModal" :title="$t('shop.order.preparation.title', {orderID: itemToUpdate._id})" hide-footer lazy>
          <PrepareOrder :order="itemToUpdate" v-on:updated="onItemUpdated" />
        </b-modal>

        <b-modal size="lg" ref="pickupModal" :title="$t('shop.order.pickup.title', {orderID: itemToUpdate._id})" hide-footer lazy>
          <PickupOrder :order="itemToUpdate" v-on:updated="onItemUpdated" />
        </b-modal>

        <b-modal size="lg" ref="checkDeliveryModal" :title="$t('shop.order.checkDelivery.title', {orderID: itemToUpdate._id})" hide-footer lazy>
          <CheckDelivery :order="itemToUpdate" v-on:updated="onItemUpdated" />
        </b-modal>

        <b-modal size="lg" ref="removeItemModal" 
            :title="$t('shop.order.remove.title', {orderID: itemToRemove._id})"
            @ok="removeItem">
          <h3>{{$t('shop.order.remove.query', {orderID: itemToRemove._id})}}</h3>
          <p v-if="itemToRemove.options && itemToRemove.options.removeReason">
            {{$t('shop.order.remove.reasons.'+itemToRemove.options.removeReason,)}}
          </p>
        </b-modal>

        <b-modal size="lg" ref="deliveredItemModal" 
            :title="$t('shop.order.delivered.title', {orderID: itemToUpdate._id})"
            :ok-title="$t('shop.order.delivered.button')"
            @ok="deliveredItem">
          <p>{{$t('shop.order.delivered.note')}}</p>
        </b-modal>

      </div>
      
    </div>
  </div>
</template>

<style scoped>
  .col-auto.buttons {
    text-align: right;
    display:flex;
  }

  .col-auto.buttons > div {
    margin:5px;  /* and that, will result in a 10px gap */
  }

  .isDelivered {
    font-weight: bold;
    color: #FF0000;
  }
</style>



<script lang="ts">
import { Ref, defineComponent, ComputedRef, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import ReviewOrder from './order/options/Review.vue';
import OrderRecap from './order/options/OrderRecap.vue';
import PrepareOrder from './order/options/Prepare.vue';
import PickupOrder from './order/options/Pickup.vue';
import CheckPayment from './order/options/CheckPayment.vue';
import SendReviewEmail from './order/options/SendReviewEmail.vue';
import CheckDelivery from './order/options/CheckDelivery.vue';
import DropdownList from '@fwk-client/components/panels/input/DropdownList.vue';

import * as shop from '@root/src/client/utils/shop';
import { languagesTypes } from '@fwk-client/store/types';

import { formatDate, formatDay } from '@igotweb-node-api-utils/formatter';

import { useOrderAdmin } from '../../composables/useOrderAdmin';

// @ts-ignore
import DateRangePicker from 'vue2-daterange-picker'


export default defineComponent({
  props: {
  },
  components: {
    CheckPayment,
    SendReviewEmail,
    CheckDelivery,
    ReviewOrder,
    OrderRecap,
    PrepareOrder,
    PickupOrder,
    DropdownList,
    DateRangePicker
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { orders, filters, availableStatus, updateListOrders, removeOrder, deliverOrder, isListLoading, getOrderActivities, getOrderProducts } = useOrderAdmin(props, context);

    const dateRangePickerLocalData:any = {
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 0
    }

    const itemToRemove:Ref<any> = ref({});
    const itemToUpdate:Ref<any> = ref({});

    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value;
    })

    const listItemsSelected:Ref<any[]> = ref([]);


    const listItems:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);
    const deliveredItemModal:Ref<HTMLElement|null> = ref(null);
    const checkDeliveryModal:Ref<HTMLElement|null> = ref(null);
    const checkPaymentModal:Ref<HTMLElement|null> = ref(null);
    const orderRecapModal:Ref<HTMLElement|null> = ref(null);
    const sendReviewEmailModal:Ref<HTMLElement|null> = ref(null);
    const reviewModal:Ref<HTMLElement|null> = ref(null);
    const preparationModal:Ref<HTMLElement|null> = ref(null);
    const pickupModal:Ref<HTMLElement|null> = ref(null);
    

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const perPage:number = 10;
    const currentPage:Ref<number> = ref(1);

    const numberOfRows:Ref<number> = computed(() => {
        return orders.value.length
    })

    const getMethodOfDeliveryLabel = (order:any) => {
        return shop.getOrderDeliveryTypeLabel(app, order);
    }

    const orderProducts = (order:any) => {
      return getOrderActivities(order);
    }

    const orderActivities = (order:any) => {
      return getOrderActivities(order);
    }

    const methodOfMethodFormatter = () => {
      return (value:any, key:any, item:any) => {
        return shop.getPaymentMethodLabel(app, item);
      }
    }

    const paymentStatusFormatter = () => {
      return (value:any, key:any, item:any) => {
        return shop.getPaymentStatusLabel(app, item);
      }
    }

    const orderStatusFormatter = () => {
      return (value:any, key:any, item:any) => {
        return shop.getOrderStatusLabel(app, item);
      }
    }

    const listFields = [
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "_id",
        label: ""
      },
      {
        key: "creationDateTime",
        label: "",
        formatter: (value:Date, key:any, item:any) => {
          return formatDate(value, currentLanguageCode.value);
        }
      },
      {
        key: "contact.email.email",
        label: ""
      },
      {
        key: "contact",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          return item.contact.firstname + " " + item.contact.lastname;
        }
      },
      {
        key: "totalAmount",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          return app.formatPriceAmount(value);
        }
      },
      {
        key: "description",
        label: ""
      },
      {
        key: "transaction.method.type",
        label: "",
        formatter: methodOfMethodFormatter()
      },
      {
        key: "transaction.status",
        label: "",
        formatter: paymentStatusFormatter()
      },
      {
        key: "status",
        label: "",
        formatter: orderStatusFormatter()
      },
      {
        key: "options"
      }
    ];

    onMounted(() => {
      listFields[1].label = app.$t('shop.orders.headers.id') as string;
      listFields[2].label = app.$t('shop.orders.headers.date') as string;
      listFields[3].label = app.$t('shop.orders.headers.email') as string;
      listFields[4].label = app.$t('shop.orders.headers.name') as string;
      listFields[5].label = app.$t('shop.orders.headers.amount') as string;
      listFields[6].label = app.$t('shop.orders.headers.description') as string;
      listFields[7].label = app.$t('shop.orders.headers.methodOfPayment') as string;
      listFields[8].label = app.$t('shop.orders.headers.paymentStatus') as string;
      listFields[9].label = app.$t('shop.orders.headers.status') as string;
      listFields[10].label = app.$t('shop.orders.headers.options') as string;

      enableAutoRefresh();
    })

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const getStatusLabel = (statusCode:string) => {
      return shop.getOrderStatusLabel(app, {
        status:statusCode
      });
    }

    const getDateRangeLabel = (startDate:Date, endDate:Date) => {
      var formattedStartDate = formatDay(startDate, currentLanguageCode.value);
      var formattedEndDate = formatDay(endDate, currentLanguageCode.value);
      var formattedToday = formatDay(new Date(), currentLanguageCode.value);
      if(startDate && endDate) {
        if(startDate.getTime() == endDate.getTime()) {
          return formattedStartDate;
        }
        return formattedStartDate + " - " + formattedEndDate;  
      }
      else if(startDate) {
        return "Après le "+formattedStartDate
      }
      else if(formattedEndDate == formattedToday) {
        return "Toutes les dates"
      }
      else {
        return "Avant le "+formattedEndDate
      }
    }

    const enableAutoRefresh = () => {
      setTimeout(() => {
        // When the component is unloaded, the timeout is still active
        updateListOrders();
        enableAutoRefresh();
      }, 5000*60);
    }

    const onRefreshClick = () => {
      updateListOrders();
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
    }

    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const confirmDeliveredItem = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      deliveredItemModal.value.show()
    }

    const showCheckPaymentModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      checkPaymentModal.value.show()
    }

    const showOrderRecapModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      orderRecapModal.value.show()
    }

    const showSendReviewEmailModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      sendReviewEmailModal.value.show()
    }

    const showReviewModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      reviewModal.value.show()
    }

    const showPreparationModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      preparationModal.value.show()
    }

    const showPickupModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      pickupModal.value.show()
    }

    const showCheckDeliveryModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      checkDeliveryModal.value.show()
    }

    const removeItem = () => {
      removeOrder(itemToRemove.value._id).then((removed:boolean) => {
        // We reset the user to remove
        itemToRemove.value = {};
      })
    }

    const deliveredItem = () => {
      deliverOrder(itemToUpdate.value._id).then((result:any) => {
        // We reset the user to remove
        itemToUpdate.value = {};
      })
    }

    return {
      orders,
      filters,
      availableStatus,

      listLoading,
      showNumberOfRows,
      numberOfRows,
      listItems,
      listFields,
      listItemsSelected,
      confirmRemoveItem,
      itemToUpdate,
      onItemUpdated,
      removeItemModal,
      removeItem,
      itemToRemove,

      deliveredItemModal,
      deliveredItem,
      
      checkDeliveryModal,
      checkPaymentModal,
      orderRecapModal,
      sendReviewEmailModal,
      reviewModal,
      preparationModal,
      pickupModal,

      getStatusLabel,
      getDateRangeLabel,
      onRefreshClick,

      confirmDeliveredItem,
      showCheckPaymentModal,
      showOrderRecapModal,
      showSendReviewEmailModal,
      showReviewModal,
      showPreparationModal,
      showPickupModal,
      showCheckDeliveryModal,

      dateRangePickerLocalData,
      perPage,
      currentPage,

      getMethodOfDeliveryLabel,
      orderProducts,
      orderActivities
    }
  }
})
</script>