<template>
  
    <div class="order-recap">

      <CartRecap :order="order"></CartRecap>

      <hr/>

      <DeliveryRecap v-if="order.methodsOfDelivery && order.methodsOfDelivery.length > 0" :order="order"></DeliveryRecap>

      <hr/>

      <ContactRecap :order="order"></ContactRecap>

      <hr/>

      <PaymentRecap :order="order"></PaymentRecap>
      
    </div>

</template>

<style>

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'

import CartRecap from '../recap/CartRecap.vue';
import DeliveryRecap from '../recap/DeliveryRecap.vue';
import ContactRecap from '../recap/ContactRecap.vue';
import PaymentRecap from '../recap/PaymentRecap.vue';


export default defineComponent({
  props: {
      order : {
        type: Object,
        required: true
      }
  },
  components: {
    CartRecap,
    DeliveryRecap,
    ContactRecap,
    PaymentRecap  
  },
  setup(props, context) {

    const { order } = toRefs(props);

    return {
      order
    }
  }
})
</script>