import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import * as api from '@fwk-client/utils/api';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '@root/src/client/store/application';
import { authenticationTypes } from '@fwk-client/store/types';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { roles as shopRoles } from '@igotweb-node-api/api/modules/shop/src/roles/index';

import { useVigneronOnlineAdmin } from '../../vigneron-online/composables/useVigneronOnlineAdmin';
import { useSiteAdmin } from '../../cms/composables/useSiteAdmin';


interface ShopAdminInput {
}

export function useShopAdmin(props:ShopAdminInput, context:any) { 
  const app = getApp();
  const store = useStore();

  const { getShopContentFromShopID } = useVigneronOnlineAdmin(props, context);
  const { getSiteFromShopID } = useSiteAdmin(props, context);

  const hasUserRole = store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

  var isListLoading:Ref<boolean> = ref(false);
  var list:Ref<any[]> = ref([]);

  const updateInputWithTargetDB = (input:any) => {
    // We check if we have a target DB for the content
    var targetDB = store.getters['application/'+applicationTypes.getters.GET_ADMIN_TARGET_DB];
    if(targetDB) {
      input.targetDB = targetDB;
    }
  }

  const callShopAdmin = async (path:string, input?:any, formData?:FormData) => {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: app
    }
    if(!input) { input = {}; }
    updateInputWithTargetDB(input);
    if(formData) {
      return api.postAPIFormData('/api/admin/shop' + path, formData, options, input)
    }
    return api.postAPI('/api/admin/shop' + path, input, options);
  }

  const updateListShops = async () => {

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/shops/list');
      store.commit('application/'+applicationTypes.mutations.SET_USER_SHOPS, response.shops ? response.shops : []);
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  const getShopFromID = (shopID:string) => {
    var shop = shops.value.filter((shop:any) => {
      return shop.shop._id == shopID
    })
    if(shop.length == 1) { return shop[0].shop; }
    return null;
  }

  /**
   * selectShop
   * This method update the selected shop by loading all associated details
   * @param shopID 
   */
  const selectShop = async (shopID:string) => {

    // We need to load the site and update the store
    try {
      var response = await callShopAdmin('/shop/'+shopID);
      store.commit('application/'+applicationTypes.mutations.SET_USER_SELECTED_SHOP, response.shop ? response.shop : undefined);
    }
    catch(error:any) {
      console.log(error);
    }
  }

  const shops = computed(() => {
    return store.getters['application/'+applicationTypes.getters.GET_USER_SHOPS];
  })

  const selectedShop = computed(() => {
    return store.getters['application/'+applicationTypes.getters.GET_USER_SELECTED_SHOP];
  })

  const selectedShopContent = computed(() => {
    return getShopContentFromShopID(selectedShop.value.shop._id);
  })

  const selectedSite = computed(() => {
    var site = getSiteFromShopID(selectedShop.value.shop._id);
    return site;
  })

  const isShopUpdateAllowed = (shopAuthRoles:string[]) => {
    return shopAuthRoles.indexOf(shopRoles.shop_admin) > -1
  }

  const isShopCreateAllowed = () => {
    return hasUserRole(apiRoles.superadmin);
  }

  const isShopRemoveAllowed = (shopAuthRoles:string[]) => {
    return shopAuthRoles.indexOf(shopRoles.shop_admin) > -1
  }

  const shopForm:any = reactive({
    company: null,
    name: ''
  });

  const updateShopFormForUpdate = (shop:any) => {
    shopForm.shopID = (shop && shop._id) ? shop._id : undefined,
    shopForm.name =  shop.name,
    shopForm.isActivated = shop.isActivated,
    shopForm.isPromoCodesAllowed = shop.isPromoCodesAllowed,
    shopForm.orderNotificationEMail = shop.orderNotificationEMail ? shop.orderNotificationEMail.email : undefined,
    shopForm.allowedProductTypes = shop.allowedProductTypes ? shop.allowedProductTypes : [],
    shopForm.allowedActivityTypes = shop.allowedActivityTypes ? shop.allowedActivityTypes : []
  }

  const createShop = async () => {

    var result:any = {
      created: false
    }

    var input = {
      "companyID" : shopForm.company!._id,
      "name" : shopForm.name
    }

    try {
      var response = await callShopAdmin('/shops/create', input);
      if(response.created) {  
        // We reset the field
        shopForm.company = null;
        shopForm.name = "";

        // We update the list of shops
        updateListShops();

        result.created = true;
        result.shop = response.shop;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateShop = async () => {

    var result:any = {
      updated: false
    }

    var input:any = {
      "shopID" : shopForm.shopID,
      "name" : shopForm.name,
      "isActivated" : shopForm.isActivated,
      "isPromoCodesAllowed" : shopForm.isPromoCodesAllowed,
      "orderNotificationEMail" : shopForm.orderNotificationEMail,
      "allowedProductTypes" : shopForm.allowedProductTypes.length > 0 ? shopForm.allowedProductTypes : undefined,
      "allowedActivityTypes" : shopForm.allowedActivityTypes.length > 0 ? shopForm.allowedActivityTypes : undefined
    }

    try {
      var response = await callShopAdmin('/shop/'+shopForm.shopID+'/update', input);
      if(response.updated) {  
        // We update the form based on values returned by backend (in case not updated)
        shopForm.name = response.shop.name;

        // We update the list of shops
        updateListShops();

        result.updated = true;
        result.shop = response.shop;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removeShop = async (shopID:string) => {

    isListLoading.value = true;

    try {
      var response = await callShopAdmin('/shop/'+shopID+'/remove');
      if(response.removed) {
        updateListShops();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  // We watch if we have site ID in URL
  watch(
    () => store.state.route.params.siteID,
    (val:any, oldVal:any) => {
      /*
      LOAD SITE
    */       
    },
    { deep: true }
  )

  return {
    callShopAdmin,
    isListLoading,
    shops,
    getShopFromID,
    selectedShop,
    selectedShopContent,
    selectedSite,
    selectShop,
    shopForm,
    createShop,
    updateShop,
    removeShop,
    isShopUpdateAllowed,
    isShopCreateAllowed,
    isShopRemoveAllowed,
    updateShopFormForUpdate,
    shopRoles
  }
  
}