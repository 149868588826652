<template>
  
    <form role="form" name="check-delivery" class="check-delivery">

      <CartRecap :order="order"></CartRecap>

      <hr/>

      <ContactRecap :order="order"></ContactRecap>

      <hr/>

      <PaymentRecap :order="order"></PaymentRecap>

      <hr/>

      <DeliveryRecap :order="order"></DeliveryRecap>

      <div v-if="order.status == 'SHIPPED'">
        <hr/>
        <h3>{{ $t('shop.order.checkDelivery.update.title') }}</h3>
        <p>{{ $t('shop.order.checkDelivery.update.note') }}</p>
        <div class="form-group">
            <label for="shippingProvider" class="sr-only">{{$t('shop.order.info.methodOfDelivery.shipping.provider')}}</label>
            <div>
              <div v-if="listShippingProviders.length == 0" class="sk-spinner sk-spinner sk-spinner-three-bounce">
                  <div class="sk-bounce1"></div>
                  <div class="sk-bounce2"></div>
                  <div class="sk-bounce3"></div>
              </div>
              <v-select v-if="listShippingProviders.length > 0" id="provider" :placeholder="$t('shop.order.info.methodOfDelivery.shipping.provider_placeholder')" v-model="form.shipping.provider" :options="listShippingProviders" label="name" :reduce="provider => provider.code"></v-select>
            </div>
        </div>
        <div class="form-group">
            <label for="shippingTrackingNumbers" class="sr-only">{{$t('shop.order.info.methodOfDelivery.shipping.trackingNumber')}}</label>
            <div v-for="(trackingNumber, index) in form.shipping.trackingNumbers" :key="'trackingNumber_'+index" class="tracking-number input-group">
              <input type="text" class="form-control" v-model="form.shipping.trackingNumbers[index]" :placeholder="$t('shop.order.info.methodOfDelivery.shipping.trackingNumber_placeholder')" />
              <span class="input-group-append" v-if="index>0">
                <button type="button" class="btn btn-primary removeTrackingNumber" @click="removeTrackingNumber($event, index)">{{ $t('shop.order.info.methodOfDelivery.shipping.remove-button') }}</button>
              </span>
            </div>
            <button class="btn btn-primary addTrackingNumber" type="button" @click="addTrackingNumber()">{{$t('shop.order.info.methodOfDelivery.shipping.add-button')}}</button>
            
        </div>
        
        <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="button" @click="onFormSubmit">{{$t('shop.order.checkDelivery.update.button')}}</button>
      </div>
      
    </form>

</template>

<style>

  .check-delivery hr {
    border-top: 2px solid rgba(0,0,0,.3);
    margin-left: -20px;
    margin-right: -20px;
  }

  .check-delivery td.header {
    white-space: nowrap;
  }

  .check-delivery .tracking-number {
    margin-bottom:5px;
  }

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import CartRecap from '../recap/CartRecap.vue';
import DeliveryRecap from '../recap/DeliveryRecap.vue';
import ContactRecap from '../recap/ContactRecap.vue';
import PaymentRecap from '../recap/PaymentRecap.vue';

import * as Ladda from 'ladda';

import { useOrderAdmin } from '../../../../composables/useOrderAdmin';

export default defineComponent({
  props: {
      order : {
        type: Object,
        required: true
      }
  },
  components: {
    CartRecap,
    DeliveryRecap,
    ContactRecap,
    PaymentRecap  
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { getShippingProviders, updateDeliveryDetails } = useOrderAdmin(props, context);

    const listShippingProviders:Ref<any[]> = ref([]);

    const { order } = toRefs(props);
  
    const form = reactive({
      shipping : {
        provider : '',
        trackingNumbers : ['']
      }
    })

    var laddaConfirmSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var confirmButton:HTMLButtonElement|null = document.querySelector( 'form[name=check-delivery] button.update' );
      laddaConfirmSubmit = Ladda.create(confirmButton!);
    });

    // We update the form with available delivery details.
    var shippingDetails = order.value.deliveryDetails;
    if(shippingDetails) {
      form.shipping.provider = shippingDetails.provider;
      form.shipping.trackingNumbers = shippingDetails.trackingNumbers;
    }

    const addTrackingNumber = () => {
      form.shipping.trackingNumbers.push('');
    }

    const removeTrackingNumber = (evt:Event, index:number) => {
      // We prevent submit of the page
      evt.preventDefault();
      form.shipping.trackingNumbers.splice(index,1);
    }

    const updateShippingProviderList = () => {
      getShippingProviders().then((providers:any[]) => {
        listShippingProviders.value = providers;
      })
    }
    updateShippingProviderList();

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaConfirmSubmit!.start();

      updateDeliveryDetails(order.value._id, form.shipping).then((result:any) => {
        if(result.updated) {
          context.emit("updated", result.order);
        }
        laddaConfirmSubmit!.stop();
      })
    }

    return {
      order,
      listShippingProviders,
      form,
      removeTrackingNumber,
      addTrackingNumber,
      onFormSubmit
    }
  }
})
</script>