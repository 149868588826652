import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import * as api from '@fwk-client/utils/api';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '@root/src/client/store/application';
import { authenticationTypes, languagesTypes } from '@fwk-client/store/types';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { roles as shopContentRoles } from '@igotweb-node-api/api/modules/vigneron-online/src/roles/index';

interface VigneronOnlineAdminInput {
}

export function useVigneronOnlineAdmin(props:VigneronOnlineAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();

  const hasUserRole = store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

  var isListLoading:Ref<boolean> = ref(false);
  var list:Ref<any[]> = ref([]);

  const updateInputWithTargetDB = (input:any) => {
    // We check if we have a target DB for the content
    var targetDB = store.getters['application/'+applicationTypes.getters.GET_ADMIN_TARGET_DB];
    if(targetDB) {
      input.targetDB = targetDB;
    }
  }

  const callVigneronOnlineAdmin = async (path:string, input?:any, formData?:FormData) => {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: app
    }
    if(!input) { input = {}; }
    updateInputWithTargetDB(input);
    if(formData) {
      return api.postAPIFormData('/api/admin/vigneron-online' + path, formData, options, input)
    }
    return api.postAPI('/api/admin/vigneron-online' + path, input, options);
  }

  const updateListShopContents = async () => {
    isListLoading.value = true;
    try {
      var response = await callVigneronOnlineAdmin('/shop-contents/list');
      store.commit('application/'+applicationTypes.mutations.SET_USER_SHOP_CONTENTS, response.shopContents ? response.shopContents : []);
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  const getShopContentFromID = (shopContentID:string) => {
    var shopContent = shopContents.value.filter((shopContent:any) => {
      return shopContent.shopContent._id == shopContentID
    })
    if(shopContent.length == 1) { return shopContent[0].shopContent; }
    return null;
  }

  const getShopContentFromShopID = (shopID:string) => {
    var shopContent = shopContents.value.filter((shopContent:any) => {
      return shopContent.shopContent.shop && shopContent.shopContent.shop._id == shopID
    })
    if(shopContent.length == 1) { return shopContent[0].shopContent; }
    return null;
  }

  /**
   * selectShopContent
   * This method update the selected shop content by loading all associated details
   * @param shopContentID 
   */
  const selectShopContent = async (shopContentID:string) => {
    // We need to load the site and update the store
    try {
      var response = await callVigneronOnlineAdmin('/shop-content/'+shopContentID);
      store.commit('application/'+applicationTypes.mutations.SET_USER_SELECTED_SHOP_CONTENT, response.shopContent ? response.shopContent : undefined);
    }
    catch(error:any) {
      console.log(error);
    }
  }

  const shopContents = computed(() => {
    return store.getters['application/'+applicationTypes.getters.GET_USER_SHOP_CONTENTS];
  })

  const selectedShopContent = computed(() => {
    return store.getters['application/'+applicationTypes.getters.GET_USER_SELECTED_SHOP_CONTENT];
  })

  const getShopContentValidationLink = (shopContent:any) => {
    if(shopContent && shopContent.path) {
      var currentLanguageCode = store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
      return "https://demo:demo@www-validation.vigneron-online.fr/"+currentLanguageCode+"/"+shopContent.path;
    }
    return undefined;
  }

  const isShopContentUpdateAllowed = (authRoles:string[]) => {
    return authRoles.indexOf(shopContentRoles.content_admin) > -1
  }

  const isShopContentCreateAllowed = () => {
    return hasUserRole(apiRoles.superadmin);
  }

  const isShopContentRemoveAllowed = (authRoles:string[]) => {
    return authRoles.indexOf(shopContentRoles.content_admin) > -1
  }

  const updateShopContentTheme = async (form:any) => {
    var input:any = {}
    var formData = new FormData();
    if(form.logo != null) {
      formData.append("logo", form.logo!, (form.logo! as File).name);  
    }
    if(form.banner != null) {
      formData.append("banner", form.banner!, (form.banner! as File).name);  
    }

    isListLoading.value = true;

    var result:any = {
      updated:false
    }

    try {
      var path = '/shop-content/'+selectedShopContent.value.shopContent._id+'/theme/update'
      var response = await callVigneronOnlineAdmin(path, input, formData);
      if(response.updated && response.shopContent) {
        
        // We update the list of shop contents
        updateListShopContents();
        // We update the selected shop content
        selectShopContent(selectedShopContent.value.shopContent._id)

        result.updated = true;
        result.shopContent = response.shopContent; 
      }
    }
    catch(error:any) {
      console.log(error);
    }
    
    isListLoading.value = false;
    return result;
  }

  const removeShopContent = async (shopContentID:string) => {

    isListLoading.value = true;

    try {
      var response = await callVigneronOnlineAdmin('/shop-content/'+shopContentID+'/remove');
      if(response.removed) {
        updateListShopContents();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  // We watch if we have site ID in URL
  watch(
    () => store.state.route.params.siteID,
    (val:any, oldVal:any) => {
      /*
      LOAD SITE
    */       
    },
    { deep: true }
  )

  return {
    isListLoading,
    shopContents,
    getShopContentFromID,
    getShopContentFromShopID,
    getShopContentValidationLink,
    selectedShopContent,
    selectShopContent,
    isShopContentUpdateAllowed,
    isShopContentCreateAllowed,
    isShopContentRemoveAllowed,
    removeShopContent,
    shopContentRoles,
    updateShopContentTheme
  }
  
}