<template>

  <div>

    <form role="form" name="send-review-email" class="send-review-email">

      <button class="btn btn-primary ladda-button" data-style="zoom-in" type="button" @click="onFormSubmit">{{$t('shop.order.sendReviewEwebmail.button')}}</button>

    </form>

  </div>

</template>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import * as Ladda from 'ladda';

import { useOrderAdmin } from '../../../../composables/useOrderAdmin';

export default defineComponent({
  props: {
      order : {
        type: Object,
        required: true
      }
  },
  components: { 
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { sendReviewEmail } = useOrderAdmin(props, context);

    const { order } = toRefs(props);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=send-review-email] button' );
      laddaSubmit = Ladda.create(submitButton!);
    });

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      sendReviewEmail(order.value._id).then((result:any) => {
        laddaSubmit!.stop();
      })
    }

    return {
      order,
      onFormSubmit
    }
  }
})
</script>