<template>
    <div class="promo-codes" ref="promoCodes">
        <div class="row applied-codes" v-if="promoCodes.length > 0">
            <div class="col-md-auto">
                {{ $t("shop.order.info.promoCodes-list") }}
            </div>
            <div class="col">
                <div v-for="(promoCode, index) in promoCodes" :key="index + '-' + promoCode.code">
                    <span class="code">{{promoCode.code}}</span>
                    <span class="note" v-if="promoCode.note">{{ getLocalizedText(promoCode.note) }}</span>
                </div>
            </div>
        </div>
    </div>
               
</template>

<style>

 .promo-codes {
     margin-bottom: 20px;
 }

 .promo-codes .applied-codes {
     margin-top: 20px;
 }

 .promo-codes .applied-codes span.code {
    font-weight: 600;
    color: #28a745
 }

 .promo-codes .applied-codes span.note {
     margin-left:10px;
 }

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { containerUtils } from '@fwk-client/modules/shop/stores/index';

@Component({
    components: {
    },
    computed: {},
})
export default class PromoCodes extends Vue {

    @Prop({
        type: Object,
        required: true
    }) readonly order!: any

    get promoCodes() {
        return containerUtils.getPromoCodes(this.order);
    }
    
    
}

</script>