<template>

  <form role="form" @submit="onFormSubmit" name="payment-confirm-transfer">
    <h3>{{ $t('shop.order.confirmTransfer.title') }}</h3>
    <p>{{ $t('shop.order.confirmTransfer.note') }}</p>
    <div class="form-group">
        <label for="transferDescription" class="sr-only">{{$t('shop.order.info.confirmTransfer.description')}}</label>
        <input type="text" class="form-control" v-model="form.description"/>
    </div>
    <button v-if="order.status == 'PAYMENT'" class="btn btn-primary ladda-button confirm-transfer" data-style="zoom-in" type="submit">{{$t('shop.order.confirmTransfer.button')}}</button>
  </form>

</template>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import * as Ladda from 'ladda';

import { useOrderAdmin } from '../../../../../composables/useOrderAdmin';

export default defineComponent({
  props: {
      order : {
        type: Object,
        required: true
      }
  },
  components: {
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { confirmTransfer } = useOrderAdmin(props, context);

    const { order } = toRefs(props);

    const form:any = reactive({
      description: ''
    })

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=payment-confirm-transfer] button.confirm-transfer' );
      laddaSubmit = Ladda.create(submitButton!);
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      confirmTransfer(order.value._id, form.description).then((result:any) => {
        laddaSubmit!.stop();
      })
    }

    return {
      onFormSubmit,
      order,
      form
    }
  }
})
</script>