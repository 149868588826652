import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '@root/src/client/store/application';
import { useShopAdmin } from "./useShopAdmin";

interface OrderAdminInput {
  storage?:"local"|"store";
  filterStatus?:string[];
}

export function useOrderAdmin(props:OrderAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedShop, callShopAdmin } = useShopAdmin(props, {emit})

  const filters:any = reactive({
    status: props.filterStatus ? props.filterStatus : ["PAYMENT", "REVIEW", "PREPARATION", "READY", "SHIPPED"],
    dateRange : {
      startDate: null,
      endDate: new Date()
    }
  })

  var storage = props.storage ? props.storage : "store";

  var isListLoading:Ref<boolean> = ref(false);
  var availableStatus:Ref<string[]> = ref([]);

  const localOrders = ref([]);

  const orders = computed(() => {
    if(storage == "local") {
      return localOrders.value;
    }
    return store.getters['application/'+applicationTypes.getters.GET_USER_ORDERS];
  })

  const getOrderFromID = (orderID:string) => {
    var order = orders.value.filter((order:any) => {
      return order._id == orderID
    })
    if(order.length == 1) { return order[0]; }
    return null;
  }

  const updateListOrders = async () => {
    var input:any = {
      status:filters.status
    }

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/orders', input);
      if(storage == "local") {
        localOrders.value = response.orders ? response.orders : [];
      }
      else {
        store.commit('application/'+applicationTypes.mutations.SET_USER_ORDERS, response.orders ? response.orders : []);
      }
      availableStatus.value = response.availableStatus ? response.availableStatus : [];
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateListOrders();


  const removeOrder = async (orderID:string) => {
    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/order/'+orderID+'/remove');
      if(response.removed) {
        updateListOrders();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  const deliverOrder = async (orderID:string) => {
    isListLoading.value = true;
    var result:any = {
      delivered: false
    }
    try {
      var response = await callShopAdmin('/order/'+orderID+'/delivered');
      if(response.delivered) {
        updateListOrders();
        result.delivered = true;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const checkCardStatus = async (orderID:string) => {
    isListLoading.value = true;
    var result:any = {
      updated: false
    }
    try {
      var response = await callShopAdmin('/order/'+orderID+'/payment/check-status');
      if(response.updated) {
        updateListOrders();
        result.updated = true;
        result.order = response.order;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const convertPaymentToTransfer = async (orderID:string, description?:string) => {
    isListLoading.value = true;
    var input = {
      description
    }
    var result:any = {
      updated: false
    }
    try {
      var response = await callShopAdmin('/order/'+orderID+'/payment/convert-transfer', input);
      if(response.updated) {
        updateListOrders();
        result.updated = true;
        result.order = response.order;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const confirmTransfer = async (orderID:string, description?:string) => {
    isListLoading.value = true;
    var input = {
      description
    }
    var result:any = {
      updated: false
    }
    try {
      var response = await callShopAdmin('/order/'+orderID+'/payment/confirm-transfer', input);
      if(response.updated) {
        updateListOrders();
        result.updated = true;
        result.order = response.order;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const sendReviewEmail = async (orderID:string) => {
    isListLoading.value = true;
    var result:any = {
      sent: false
    }
    try {
      var response = await callShopAdmin('/order/'+orderID+'/review/send-email');
      if(response.sent) {
        result.sent = true;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const reviewConfirm = async (orderID:string) => {
    isListLoading.value = true;
    var result:any = {
      confirmed: false
    }
    try {
      var response = await callShopAdmin('/order/'+orderID+'/review/confirm');
      if(response.confirmed) {
        updateListOrders();
        result.confirmed = true;
        result.order = response.order;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const reviewCancel = async (orderID:string, reason?:string) => {
    var input = {
      "reason" : reason
    }
    isListLoading.value = true;
    var result:any = {
      cancelled: false
    }
    try {
      var response = await callShopAdmin('/order/'+orderID+'/review/cancel', input);
      if(response.cancelled) {
        updateListOrders();
        result.cancelled = true;
        result.order = response.order;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const updateDeliveryDetails = async (orderID:string, shippingDetails?:{provider:string, trackingNumbers:string[]}) => {
    isListLoading.value = true;
    var result:any = {
      updated: false
    }
    try {
      var response = await callShopAdmin('/order/'+orderID+'/delivery/update-details', shippingDetails);
      if(response.updated) {
        updateListOrders();
        result.updated = true;
        result.order = response.order;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const getShippingProviders = async () => {
    var providers = [];
    try {
      var response = await callShopAdmin('/delivery/shipping-providers');
      if(response.shippingProviders) {
        providers = response.shippingProviders;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return providers;
  }

  const confirmPickup = async (orderID:string) => {
    isListLoading.value = true;
    var result:any = {
      confirmed: false
    }
    try {
      var response = await callShopAdmin('/order/'+orderID+'/delivery/pickup-confirm');
      if(response.confirmed) {
        updateListOrders();
        result.confirmed = true;
        result.order = response.order;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const confirmPreparation = async (orderID:string, shippingDetails?:{provider:string, trackingNumbers:string[]}) => {
    isListLoading.value = true;
    var result:any = {
      confirmed: false
    }
    try {
      var response = await callShopAdmin('/order/'+orderID+'/prepare/confirm', shippingDetails);
      if(response.confirmed) {
        updateListOrders();
        result.confirmed = true;
        result.order = response.order;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const getOrderProducts = (order:any) => {
    return order.products.filter((cartProduct:any) => {
      return cartProduct.type == 'PRODUCT';
    })
  }

  const getOrderActivities = (order:any) => {
    return order.products.filter((cartProduct:any) => {
      return cartProduct.type == 'ACTIVITY';
    })
  }

  // We check when there is a new shop selected
  watch(
    () => selectedShop.value.shop._id,
    (val:any, oldVal:any) => {
      // We update the categories when the selected shop is updated
      updateListOrders();
    },
    { deep: false }
  )

  return {
    orders,
    getOrderFromID,
    isListLoading,
    filters,
    availableStatus,
    updateListOrders,
    removeOrder,
    deliverOrder,
    checkCardStatus,
    convertPaymentToTransfer,
    confirmTransfer,
    sendReviewEmail,
    reviewConfirm,
    reviewCancel,
    updateDeliveryDetails,
    getShippingProviders,
    confirmPickup,
    confirmPreparation,
    getOrderProducts,
    getOrderActivities
  }
  
}