<template>
  
    <form role="form" name="pickup-order" class="pickup-order">

      <CartRecap :order="order"></CartRecap>

      <hr/>

      <ContactRecap :order="order"></ContactRecap>

      <hr/>

      <PaymentRecap :order="order"></PaymentRecap>

      <div v-if="order.status == 'READY'">

        <hr/>

        <DeliveryRecap :order="order"></DeliveryRecap>

        <hr/>
        <h3>{{ $t('shop.order.pickup.confirm.title') }}</h3>
        <p>{{ $t('shop.order.pickup.confirm.note') }}</p>
        <button class="btn btn-primary ladda-button pickup" data-style="zoom-in" type="button" @click="onFormSubmit">{{$t('shop.order.pickup.button')}}</button>
      
      </div>
      
    </form>

</template>

<style>

  .prepare-order hr {
    border-top: 2px solid rgba(0,0,0,.3);
    margin-left: -20px;
    margin-right: -20px;
  }

  .prepare-order td.header {
    white-space: nowrap;
  }

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import CartRecap from '../recap/CartRecap.vue';
import DeliveryRecap from '../recap/DeliveryRecap.vue';
import ContactRecap from '../recap/ContactRecap.vue';
import PaymentRecap from '../recap/PaymentRecap.vue';

import * as Ladda from 'ladda';

import { useOrderAdmin } from '../../../../composables/useOrderAdmin';

export default defineComponent({
  props: {
      order : {
        type: Object,
        required: true
      }
  },
  components: {
    CartRecap,
    DeliveryRecap,
    ContactRecap,
    PaymentRecap  
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { confirmPickup } = useOrderAdmin(props, context);

    const { order } = toRefs(props);

    var laddaConfirmSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var confirmButton:HTMLButtonElement|null = document.querySelector( 'form[name=pickup-order] button' );
      laddaConfirmSubmit = Ladda.create(confirmButton!);
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaConfirmSubmit!.start();

      confirmPickup(order.value._id).then((result:any) => {
        if(result.confirmed) {
          context.emit("updated", result.order);
        }
        laddaConfirmSubmit!.stop();
      })
    }

    return {
      order,
      onFormSubmit
    }
    
  }
})
</script>