<template>
    <div>
        <h3>{{ $t('shop.order.info.methodOfDelivery.title') }}: <span>{{ getDeliveryTitle() }}</span></h3>
        <div v-if="$shop.delivery.getOrderDeliveryType(order) == 'PICKUP'">
            
        </div>
        <div v-if="$shop.delivery.getOrderDeliveryType(order) == 'SHIPPING'">
            <div v-if="order.deliveryDetails">
                <h4>Details de livraison</h4>                
                <div class="table-responsive details">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="header">Date</td>
                                <td class="col">{{ deliveryDate }}</td>
                            </tr>
                            <tr v-if="order.deliveryDetails.provider">
                                <td class="header">Livreur</td>
                                <td class="col">{{ order.deliveryDetails.provider }}</td>
                            </tr>
                            <tr v-if="order.deliveryDetails.trackingNumbers">
                                <td class="header">Références</td>
                                <td class="col">{{ order.deliveryDetails.trackingNumbers.join(", ") }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="shippingStatus">
                <div v-for="(status, index) in shippingStatus" :key="'shippingStatus_'+index">
                    <h4>Suivi de livraison ({{status.trackingNumber}})</h4>
                    <div v-if="status.status == 'NA'">
                        Status non disponible
                    </div>
                    <div v-else class="table-responsive details">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td class="header">{{ formatHistoryDate(status.history[0].date) }}</td>
                                    <td class="col">{{ status.history[0].description }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="shippingHistoryCollapse" v-if="status.history.length > 1">
                            <a href="javascript:void(0)" @click="onDeliveryHistoryClick(index)" :class="{'opened':historyOpened[index]}">
                                    Historique <i class="fa fa-chevron-down"></i>
                            </a>
                            <b-collapse v-model="historyOpened[index]" class="history-content">
                                <table class="table">
                                    <tbody>
                                        <tr v-for="(history, historyIndex) in status.history" :key="'shippingStatus_'+index+'_history_'+historyIndex">
                                            <td class="header">{{ formatHistoryDate(history.date) }}</td>
                                            <td class="col">{{ history.description }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-collapse>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="order.methodsOfDelivery.length > 1">
                <h4>Méthodes de livraison</h4>
                <ul>
                    <li v-for="(delivery,index) of order.methodsOfDelivery" :key="'delivery-'+index">
                        {{ getLocalizedText(delivery.title) }}
                    </li>
                </ul>
            </div>
            <h4>Adresse de livraison</h4>
            <Address :address="(order.deliveryDetails && order.deliveryDetails.deliveryAddress) ? order.deliveryDetails.deliveryAddress : order.contact.address"></Address>
        </div>
    </div>
</template>

<style>

    .shippingHistoryCollapse > a i:last-child {
        margin-left:5px;
        transition: 0.3s ease all;
    }

    .shippingHistoryCollapse > a.opened i:last-child {
        transform: rotate(180deg);
    }

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import Address from './Address.vue';

import * as shop from '@root/src/client/utils/shop';

import { formatDate, FormatDateType } from '@igotweb-node-api-utils/formatter';

@Component({
    components: {
        Address
    },
    computed: {
        
    },
})
export default class DeliveryRecap extends Vue {

    @Prop({
        type: Object,
        required: true
    }) readonly order!: any

    shippingStatus:any = null;
    historyOpened:boolean[] = [];

    created() {
        if(this.$shop.delivery.getOrderDeliveryType(this.order) == 'SHIPPING' && this.order.deliveryDetails) {
            // We check the delivery status
            var input = {
                "email" : this.order.contact.email.email
            }
        
            var options:api.ApiVueOptions =  {
                app: this
            }
            
            api.postAPI('/api/admin/shop/order/'+this.order._id+'/delivery/shipping-status', input, options).then((response:any) => {
                if(response.status) {
                    this.shippingStatus = response.status;
                    this.historyOpened = [...this.shippingStatus].map((status) => {
                        return false;
                    })
                }
            });
        }
    }

    get deliveryDate() {
        return formatDate(this.order.deliveryDetails.date, this.$store.state.languages.currentLanguageCode, {type:FormatDateType.LONG});
    }

    formatHistoryDate(date:Date) {
        return formatDate(date, this.$store.state.languages.currentLanguageCode, {type:FormatDateType.LONG});
    }

    getDeliveryTitle() {
        return shop.getOrderDeliveryTypeLabel(this, this.order);
    }

    onDeliveryHistoryClick(index:number) {
        Vue.set(this.historyOpened,index,!this.historyOpened[index]);
    }
}

</script>