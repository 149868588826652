<template>
    <table class="table cart">
        <thead>
            <tr>
                <th class="cart-product-name">{{ $t("shop.products.products.headers.reference") }}</th>
                <th class="cart-product-name">{{ $t("shop.products.products.headers.title") }}</th>
                <th class="cart-product-price">{{ $t("shop.products.products.headers.price") }}</th>
                <th class="cart-product-quantity">{{ $t("shop.products.products.headers.quantity") }}</th>
                <th class="cart-product-subtotal">{{ $t("shop.products.products.headers.total-price") }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(cartProduct, index) in products" :key="index + '-cart-product'" class="cart_item" ref="cartProduct">

                <td class="cart-product-reference">
                    {{cartProduct.product.reference}}
                </td>

                <td class="cart-product-name">
                    <div class="title">{{cartProduct.product.title}}</div>
                    <div class="subtitle" v-if="cartProduct.product.subtitle && cartProduct.product.subtitle != ''">{{cartProduct.product.subtitle}}</div>
                    <div class="capacity" v-if="cartProduct.product.capacity">{{cartProduct.product.capacity}}cl</div>
                </td>

                <td class="cart-product-price">
                    <span class="amount">{{ formatPriceAmount(cartProduct.product.price) }}</span>
                </td>

                <td class="cart-product-quantity">
                    <span>{{ cartProduct.quantity }}</span>
                </td>

                <td class="cart-product-subtotal">
                    <span class="amount">{{ formatPriceAmount(cartProduct.amount) }}</span>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';

@Component({
    components: {
    },
    computed: {},
})
export default class Products extends Vue {

    @Prop({
        type: Array,
        required: true
    }) readonly products!: any[]

}

</script>